<template>
    <div>
      <Section1 :keyWord="'Préstamo ideal para dentistas y clínicas dentales'" />
      <div class="texto-landing">
        <!-- Escribe aquí gaston, gracias :) -->
        <h2 class="title-h1 text-center pb-4"><strong>Préstamo para dentista</strong></h2>
        <p>Son muchos los gastos a los que tienen que hacer frente las familias, el pago de facturas, hipotecas o gastos derivados de la alimentación. Además hay otra serie de imprevistos que pueden hacernos tener que desembolsar una cantidad importante de dinero, como los servicios odontológicos. En ocasiones las familias no pueden asumir ciertas cuantías económicas para estos servicios.</p>
        <p>El dentista es un profesional necesario para cuidar de nuestra salud, sin embargo a veces puede ser difícil abordar los presupuestos de estos especialistas. Con Ibancar podrás tener un <strong>préstamo</strong> para dentista en tan solo 24 horas. Somos un servicio financiero que apoya a las familias y les ayuda a solucionar sus problemas.</p>
  
        <h3 class="naranja"><strong>El préstamo para dentista con tu coche</strong></h3>
        <p>Con Ibancar podrás obtener el <strong>préstamo para dentista</strong> que tú y tu familia necesitáis. A veces solicitar créditos puede resultar incómodo o podemos estar temerosos de avalar con propiedades, en este sentido, <a href="https://ibancar.com">Ibancar avala el crédito con tu vehículo, con total seguridad y garantía</a>. Así para avalar con tu coche no tendrás que entregar ni llaves ni documentación del mismo puesto que seguirás siendo el dueño en todo momento.</p>
        <p>Con un préstamo de Ibancar no tendrás que avalar con propiedades, con nóminas ni implicar a terceras personas, Ibancar solo te pedirá de aval tu vehículo. Además se trata de un proceso totalmente online lo que facilita su solicitud. Una vez iniciado el proceso se te enviará un presupuesto personalizado a ti, es entonces cuando una vez aceptado contarás con el dinero en un plazo de 24 horas.</p>
  
        <h3 class="naranja"><strong>Todo lo que Ibancar te ofrece</strong></h3>
        <p>Obtener un préstamo para dentista con Ibancar es muy sencillo y con ello obtendrás una serie de beneficios que te harán decantarte por solicitarlo y así afrontar estos servicios odontológicos y dejar de posponerlos en el tiempo. Las ventajas del crédito de Ibancar son:</p>
        <ul>
            <li>Siempre tendrás tu coche disponible. Podrás seguir usándolo como hasta ahora y es que no se te pedirá ni cambio de titularidad ni la entrega del mismo.</li>
            <li>Consigue hasta un importe máximo de 6.000 euros en solo 24 horas desde que inicies el proceso. El proceso se realiza rellenando un formulario web, por lo que podrás hacerlo desde cualquier punto y en cualquier momento del día.</li>
            <li>Tendrás la asesoría continua de uno de nuestros gestores, para que estés acompañado y guiado en todo el proceso.</li>
            <li>Garantía de amortización del préstamo por siniestro total o posibilidad de entregar el vehículo por siniestro parcial.</li>
            <li>Revisión técnica al año para mantenimiento del vehículo.</li>
            <li>Servicio automático de aviso de la caducidad de citas de la ITV.</li>
            <li>Área de cliente personalizada donde podrás acceder siempre a tu información personal.</li>
            <li>Informe Ganvam para conocer el estado del coche.</li>
            <li>Flexibilidad en los pagos de las cuotas.</li>
            <li>Posibilidad de pago con tarjeta o ingresos bancarios en la fecha que tú elijas.</li>
        </ul>

        <p>No renuncies a mejorar la salud de tu boca y la de tu familia, ahora con Ibancar tienes todo lo que necesitas, un <strong>préstamo para dentista</strong> con el único requisito de avalar con tu coche. Contacta con Ibancar y empieza a mirar por la salud de tu familia.</p>  
        <!-- Boton Préstamo -->
        <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="flex-column pt-5">
                <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
            </div>
        </div>
      </div>
      <Section2 />
      <CreditoCocheS4 /> <!-- acordeón de preguntas -->
      <div class="relleno"></div>
      
      <div class="cards-prestamo">
        <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
          <div class="padding">
              <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
          </div>
        </div>
  
        <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
            <div class="card m-4"  >
              <a href="../prestamo-por-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamo por tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../dinero-rapido-con-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Dinero rápido con tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
                </div>
              </a>
            </div>
        </div>
        
        
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-madrid" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en Madrid</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Crédito por tu coche en Madrid. Recibe dinero por tu coche en Madrid</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-sevilla" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en &nbsp; Sevilla</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Credito por tu coche en Sevilla. Recibe dinero por tu coche en Sevilla</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-valencia" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en Valencia</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Crédito por tu coche en Valencia. Recibe dinero por tu coche en Valencia.</p>
                </div>
              </a>
            </div>
        </div>
  
        
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4"  >
              <a href="../credito-aval-coche-las-palmas" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito aval coche Las &nbsp; Palmas</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero usando tu coche como aval sin cambiar de titularidad.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
                </div>
              </a>
            </div>
        </div> 
  
  <!-- fila landing 4  -->
        <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
              <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-por-tu-coche-alicante" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos por tu coche Alicante</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">¿Necesitas un préstamo por tu coche en Alicante? Pues, tenemos la solución, entra aquí.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-aval-coche-valencia" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos aval coche &nbsp; Valencia</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
                </div>
              </a>
            </div>
      
        </div>
  
  <!-- fila landing 5  -->
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4"  >
              <a href="../credito-aval-coche-con-asnef" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito aval coche con Asnef</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../credito-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito con aval</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
                </div>
              </a>
            </div>
        </div>
  
        <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="flex-column p-5">
                <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
            </div>
        </div>
  
      </div>
      <div class="relleno"></div>
      <!-- No te pases de aquí gaston, gracias 2.0 :) -->
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Section1 from '@/components/Home/Section1.vue'
  import { useMeta } from 'vue-meta'
  import store from "@/store";
  import Section2 from '@/components/Home/Section2.vue'
  import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'
  
  // @ is an alias to /src
  export default {
    name: 'PrestamoDentista',
    components: {
      Section1,
      Section2,
      CreditoCocheS4
    },
    setup() {
  
      useMeta({
        title: 'Préstamo para dentista con Ibancar',
        meta:[
          {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
          {name:'description', content:'Préstamo para dentista con Ibancar'},
          {name:'keywords', content:'préstamo para dentista'}
        ]
      })
      }
  }
  </script>
  
  <style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .naranja {
    color: $orange;
    font-weight: bold;
  }
  .texto-landing {
    padding: 3em 15em;
    line-height: 2em;
  }
  .card {
    width: 22rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 10rem;
  }
  .inline {
    display: inline;
  }
  .caret-sign, .caret-sign-city {
    margin-left: 25px;
    vertical-align: top;
    float: right;
  }
  .caret-sign-city {
    position: relative;
    top: -27px;
  }
  a.no {
    text-decoration: none;
    color: #333;
  }
  a.no:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    bottom: 1px;
    height: 10rem;
  }
  .relleno {
    height: 50px;
  }
  
  @import 'bootstrap/scss/_functions.scss';
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss';
  
  @include media-breakpoint-between(xs,sm) {
    .texto-landing {
      padding: 1.5em 2em;
    }
    .card {
      height: 100%;
    }
    a.no:hover {
      height: 100%;
    }
    .title-h1 {
      font-size: 32px;
    }
    .naranja {
      font-size: 24px;
    }
  }
  
  @include media-breakpoint-between(md,lg) {
    .texto-landing {
      padding: 1.5em 5em;
    }
  }
  
  @include media-breakpoint-between(xl,xxl) {
  
  }
  </style>