<template>
  <div>
    <Section1 :keyWord="'Préstamo para liquidar deudas con hacienda'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Descubre nuestro mejor préstamo para pagar a Hacienda</strong></h2>
      <p>¿Te enfrentas a una deuda con Hacienda y requieres de un préstamo rápido y eficiente para cumplir con tus obligaciones fiscales? ¡Ibancar tiene la solución ideal para ti! Nuestro <strong>préstamo para pagar Hacienda</strong> te proporciona la financiación que necesitas.</p>
      <p>Sabemos que la declaración anual de la renta puede acarrear sorpresas económicas inesperadas, y precisamente por ello, nuestro <strong>préstamo para pagar Hacienda</strong> tiene como objetivo brindarte el respaldo financiero esencial en momentos críticos.</p>
      <p>Con Ibancar, puedes confiar en que recibirás un servicio de alta calidad y soluciones adecuadas a tus necesidades.</p>

      <h3 class="naranja"><strong>¿Por qué elegir el préstamo para pagar Hacienda de Ibancar?</strong></h3>
      <h3 class="naranja"><strong> Solución rápida y sencilla</strong></h3>
      <p>Sabemos que lidiar con Hacienda puede ser muy estresante. Por eso, ofrecemos una solución fácil para que puedas cumplir con tus obligaciones fiscales sin complicaciones ni demoras.</p>

      <h3 class="naranja"><strong>Flexibilidad y sin comisiones ocultas</strong></h3>
      <p>El <strong>préstamo para pagar Hacienda</strong> de Ibancar se adapta a tus necesidades individuales, ofreciéndote plazos de devolución flexibles ajustados a tus posibilidades económicas. Además, te ofrecemos total transparencia en nuestras condiciones y costes asociados para que puedas tomar decisiones informadas sin sorpresas ocultas.</p>

      <h3 class="naranja"><strong>Un equipo experto a tu disposición</strong></h3>
      <p>Si tienes alguna duda sobre nuestro préstamo para pagar Hacienda, contamos con un equipo de profesionales que está disponibles para responder todas tus preguntas. Estamos comprometidos a ayudarte durante todo el proceso para que obtengas una solución satisfactoria.</p>
      
      <h3 class="naranja"><strong>Préstamo con coche como aval</strong></h3>
      <p>Con Ibancar, evitas poner en peligro tu hogar al solicitar un préstamo. En lugar de ello, empleamos tu coche como aval. La gran ventaja de esto es que podrás continuar utilizando tu vehículo durante toda la vigencia del contrato sin necesidad de cambiar la titularidad ni pagar una cuota de alquiler por su uso.</p>
      <p>De este modo, accederás a los recursos financieros que requieres para liquidar tus obligaciones fiscales, preservando al mismo tiempo la libertad y movilidad que te ofrece tu coche.</p>
      
      <h3 class="naranja"><strong>¿Cómo funciona el préstamo para pagar Hacienda?</strong></h3>
      <ol>
          <li>Solicita tu préstamo. Deberás rellenar nuestro formulario en línea con tus datos personales y los de tu coche.</li>
          <li>Propuesta personalizada. Recibirás una propuesta personalizada con el importe y plazo de devolución que mejor se adapten a tu situación.</li>
          <li>Envío de documentación. Si estás de acuerdo con la propuesta, deberás enviar la documentación requerida para completar el trámite.</li>
          <li>Recibe el dinero. Una vez enviados los documentos necesarios, recibirás el dinero en tu cuenta bancaria en menos de 24 horas.</li>
        </ol>
        
        <h3 class="naranja"><strong>Requisitos para solicitar un préstamo para pagar Hacienda con Ibancar</strong></h3>
        <ul>
            <li>Ser mayor de 18 años y residente en España.</li>
            <li>Ser titular de un vehículo en regla y con menos de 14 años de antigüedad.</li>
            <li>El coche debe estar ubicado en el territorio peninsular o Islas Baleares y debe estar libre de cargas.</li>
            <li>Tener ingresos regulares demostrables (nómina, pensión, autónomo, etc.).</li>
        </ul>

        <p>No esperes más y solicita tu <strong>préstamo para pagar Hacienda</strong> con Ibancar. La solución financiera rápida, flexible y adaptada a tus necesidades está a solo unos clics de distancia.</p>
        <p>¡Únete a los miles de clientes satisfechos que ya han confiado en nosotros!</p>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamo por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Dinero rápido con tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      
      
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-madrid" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en Madrid</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Madrid. Recibe dinero por tu coche en Madrid</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-sevilla" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en &nbsp; Sevilla</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Credito por tu coche en Sevilla. Recibe dinero por tu coche en Sevilla</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-valencia" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en Valencia</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Valencia. Recibe dinero por tu coche en Valencia.</p>
              </div>
            </a>
          </div>
      </div>

      
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-las-palmas" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito aval coche Las &nbsp; Palmas</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero usando tu coche como aval sin cambiar de titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
      </div> 

<!-- fila landing 4  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4"  >
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-por-tu-coche-alicante" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos por tu coche Alicante</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">¿Necesitas un préstamo por tu coche en Alicante? Pues, tenemos la solución, entra aquí.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-aval-coche-valencia" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos aval coche &nbsp; Valencia</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
    
      </div>

<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito aval coche con Asnef</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-con-aval" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'PrestamoHacienda',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Prestamo para pagar hacienda',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Préstamo para pagar a Hacienda con Ibancar'},
        {name:'keywords', content:'prestamo para pagar hacienda'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}

</style>