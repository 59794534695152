<template>
    <div>
      <Section1 :keyWord="'Préstamo para docentes: tu solución financiera'" />
      <div class="texto-landing">
        <!-- Escribe aquí gaston, gracias :) -->
        <h2 class="title-h1 text-center pb-4"><strong>Préstamo para docentes</strong></h2>
        <p>En Ibancar sabemos que no podemos abarcar a todo cuanto nos gustaría por lo que hemos decidido situarnos al lado de nuestros usuarios y brindarles la oportunidad de hacer realidad todos sus proyectos. Si eres profesor y tienes sueños que hacer realidad o necesitas un impulso económico para salir de una situación complicada, el <strong>préstamo para docentes</strong> de Ibancar será una gran ayuda.</p>
        <p>En Ibancar somos un servicio financiero que te proporciona ese crédito que necesitas, con solo avalar  tu vehículo. Con nosotros podrás olvidarte de avalar con propiedades o de implicar en ello a otras personas, solo necesitas ser el titular del coche para poder acceder a él.</p>
  
        <h3 class="naranja"><strong>¿Cómo accedo al préstamo para docentes?</strong></h3>
        <p>Para poder conseguir el <strong>préstamo para docentes</strong> de Ibancar debes cumplir los siguientes requisitos que te exponemos a continuación:</p>
        <ul>
            <li>Ser mayor de 18 años.</li>
            <li>Ser residente en España.</li>
            <li>Ser titular de un vehículo en regla y que este tenga menos de 14 años de antigüedad.</li>
            <li>El vehículo debe estar libre de cargas y en territorio peninsular o balear.</li>
            <li>Tener ingresos regulares demostrables tales como nómina, pensión o  autónomo.</li>
        </ul>
  
        <h3 class="naranja"><strong>Características del préstamo para docentes Ibancar</strong></h3>
        <p>El <strong>préstamo para docentes</strong> de Ibancar te permite tener a tu disposición una cuantía máxima de 6.000 euros con la sola garantía del vehículo. Ibancar no te solicitará el coche, ni las llaves ni documentos sobre el mismo, todo son comodidades y claridad. Entre las características de este crédito encontramos las siguientes:</p>
        <ul>
            <li>No habrá cambios en la titularidad del vehículo.</li>
            <li>No tendrás que entregar llaves ni documentación.</li>
            <li> Podrás seguir disponiendo y manejando tu coche.</li>
            <li>Puedes acceder a servicios exclusivos como revisión por mantenimiento, recordatorios de citas de ITV, garantía de amortización por siniestro, flexibilidad de métodos de pagos o servicio de compra del coche si no puedes hacer frente al pago del préstamo.</li>
            <li>Proceso 100% online.</li>
            <li>Siempre acompañado por profesionales y un gestor que te guiará.</li>
        </ul>
  
        <h3 class="naranja"><strong>¿Cómo solicito el préstamo de Ibancar?</strong></h3>
        <p>Para acceder al <strong>préstamo para docentes</strong> con el <a href="https://ibancar.com">coche como aval</a> solo tendrás que rellenar el formulario que encontrarás en nuestra página web y seguir los pasos que se te indicarán. Tras analizar tu propuesta se te enviará un presupuesto personalizado que podrás o no, ya que no existe compromiso alguno.</p>
        <p>Para poder iniciar el trámite deberás aportar la siguiente documentación a nuestro personal:</p>
        <ul>
            <li>DNI por ambas caras.</li>
            <li> Demostrar unos ingresos superiores a 600 euros con la última nómina, pensión o autónomo.</li>
            <li> Foto selfie con el DNI.</li>
            <li>Permiso de circulación y la ficha técnica.</li>
            <li>Una foto del cuentakilómetros con el DNI</li>
            <li>Cuatro fotos del vehículo.</li>
            <li>Un video del coche.</li>
        </ul>
        <p>Si eres docente opta por ese crédito que te ayudará a cumplir todos tus sueños, así que contacta ya con Ibancar y empieza a construir tu futuro tal y como lo habías imaginado. Una solución financiera rápida, flexible y totalmente personalizada. ¡Llama ya y únete a los más de mil clientes que han hecho realidad sus sueños!</p>
  
        <!-- Boton Préstamo -->
        <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="flex-column pt-5">
                <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
            </div>
        </div>
      </div>
      <Section2 />
      <CreditoCocheS4 /> <!-- acordeón de preguntas -->
      <div class="relleno"></div>
      
      <div class="cards-prestamo">
        <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
          <div class="padding">
              <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
          </div>
        </div>
  
        <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
            <div class="card m-4"  >
              <a href="../prestamo-por-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamo por tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../dinero-rapido-con-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Dinero rápido con tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
                </div>
              </a>
            </div>
        </div>
        
        
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-madrid" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en Madrid</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Crédito por tu coche en Madrid. Recibe dinero por tu coche en Madrid</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-sevilla" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en &nbsp; Sevilla</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Credito por tu coche en Sevilla. Recibe dinero por tu coche en Sevilla</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-valencia" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en Valencia</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Crédito por tu coche en Valencia. Recibe dinero por tu coche en Valencia.</p>
                </div>
              </a>
            </div>
        </div>
  
        
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4"  >
              <a href="../credito-aval-coche-las-palmas" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito aval coche Las &nbsp; Palmas</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero usando tu coche como aval sin cambiar de titularidad.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
                </div>
              </a>
            </div>
        </div> 
  
  <!-- fila landing 4  -->
        <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
              <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-por-tu-coche-alicante" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos por tu coche Alicante</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">¿Necesitas un préstamo por tu coche en Alicante? Pues, tenemos la solución, entra aquí.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-aval-coche-valencia" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos aval coche &nbsp; Valencia</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
                </div>
              </a>
            </div>
      
        </div>
  
  <!-- fila landing 5  -->
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4"  >
              <a href="../credito-aval-coche-con-asnef" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito aval coche con Asnef</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../credito-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito con aval</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
                </div>
              </a>
            </div>
        </div>
  
        <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="flex-column p-5">
                <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
            </div>
        </div>
  
      </div>
      <div class="relleno"></div>
      <!-- No te pases de aquí gaston, gracias 2.0 :) -->
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Section1 from '@/components/Home/Section1.vue'
  import { useMeta } from 'vue-meta'
  import store from "@/store";
  import Section2 from '@/components/Home/Section2.vue'
  import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'
  
  // @ is an alias to /src
  export default {
    name: 'PrestamoDocentes',
    components: {
      Section1,
      Section2,
      CreditoCocheS4
    },
    setup() {
  
      useMeta({
        title: 'Préstamo para docentes con Ibancar',
        meta:[
          {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
          {name:'description', content:'Préstamo para docentes con Ibancar'},
          {name:'keywords', content:'préstamo para docentes'}
        ]
      })
      }
  }
  </script>
  
  <style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .naranja {
    color: $orange;
    font-weight: bold;
  }
  .texto-landing {
    padding: 3em 15em;
    line-height: 2em;
  }
  .card {
    width: 22rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 10rem;
  }
  .inline {
    display: inline;
  }
  .caret-sign, .caret-sign-city {
    margin-left: 25px;
    vertical-align: top;
    float: right;
  }
  .caret-sign-city {
    position: relative;
    top: -27px;
  }
  a.no {
    text-decoration: none;
    color: #333;
  }
  a.no:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    bottom: 1px;
    height: 10rem;
  }
  .relleno {
    height: 50px;
  }
  
  @import 'bootstrap/scss/_functions.scss';
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss';
  
  @include media-breakpoint-between(xs,sm) {
    .texto-landing {
      padding: 1.5em 2em;
    }
    .card {
      height: 100%;
    }
    a.no:hover {
      height: 100%;
    }
    .title-h1 {
      font-size: 32px;
    }
    .naranja {
      font-size: 24px;
    }
  }
  
  @include media-breakpoint-between(md,lg) {
    .texto-landing {
      padding: 1.5em 5em;
    }
  }
  
  @include media-breakpoint-between(xl,xxl) {
  
  }
  </style>