<template>
    <div>
      <Section1 :keyWord="'Préstamo para autónomos: impulsa tu negocio'" />
      <div class="texto-landing">
        <!-- Escribe aquí gaston, gracias :) -->
        <h2 class="title-h1 text-center pb-4"><strong>Préstamo para autónomos</strong></h2>
        <p>¿Eres autónomo y necesitas un préstamo? En Ibancar estamos al lado de los autónomos, y es por eso que les ayudamos a seguir avanzando y conseguir todas las metas que se propongan. Si eres autónomo y necesitas un crédito rápido para cualquier proyecto que tengas en mente, con la finalidad de seguir haciendo prosperar tu negocio, Ibancar es tu mejor aliado.</p>
        <p>Gracias al <strong>préstamo para autónomos</strong> que te ofrece Ibancar podrás conseguir ese dinero que necesitas y hacer realidad tus sueños. En Ibancar somos conscientes que en ocasiones los autónomos no lo tienen nada fácil, y se ven abocados a pagar impuestos abultados, por lo que cualquier ayuda es poca. Ahora con Ibancar obtendrás de una manera ágil, cómoda y rápida esa cantidad de dinero que necesitas.</p>
  
        <h3 class="naranja"><strong>Cómo funciona el préstamo para autónomos de Ibancar</strong></h3>
        <p>Ibancar se presenta como el único préstamo como el coche como garantía. Podrás conseguir tu <strong>préstamo para autónomos</strong> en solo un minuto, sin tareas arduas de papeleos y aporte de documentación. Un préstamo gratis y sin compromiso con el único aval de tu vehículo.</p>
        <p>Ahora que sabes que tu coche puede ayudarte a lograr tus proyectos, te preguntarás cómo puedes solicitarlo. Debes saber que el proceso se ejecuta de forma online al 100% y solo deberás seguir los siguientes pasos que te detallamos a continuación:</p>
        <ul>
            <li>Indicar la matrícula y tus datos personales de contacto.</li>
            <li>Ibancar te enviará una propuesta de préstamo totalmente personalizada sin compromiso alguno.</li>
            <li>Si te interesa la propuesta, envíanos entonces la documentación y la revisaremos al momento para agilizar todo el proceso.</li>
            <li>Obtendrás el dinero en tu cuenta bancaria en menos de 24 horas.</li>
        </ul>
  
        <h3 class="naranja"><strong>¿Qué hace a Ibancar ser líder en el sector?</strong></h3>
        <p>Ibancar será tu mejor opción para conseguir ese <strong>préstamo para autónomos</strong> que necesitas. Desde Ibancar trabajamos para dar la máxima calidad y el mejor servicio a todos nuestros clientes, es por ello, que sabemos que tu vehículo es importante para ti, por lo que avalar con él no conlleva en ningún momento el cambio de titularidad del mismo, ni tampoco te pediremos documentación ni las llaves. El coche siempre será tuyo.</p>
        <p>Para poder ayudar a nuestros usuarios contamos con los mejores profesionales, y cada cliente tendrá un trato personalizado gracias a la asignación de un gestor. Podrá resolverte todas las dudas que tengas en el proceso.</p>
        <p>El proceso de solicitud del préstamo para autónomos te llevará pocos minutos y es que se trata de un trámite online de rápida respuesta. Además desde tu área privada podrás consultar toda la información que concierne a tu préstamo en cualquier momento.</p>
        <p>Si eres autónomo no esperes más y cuenta con Ibancar, <a href="https://ibancar.com">con el aval de tu vehículo recibirás de forma inmediata el dinero que necesitas, hasta un máximo de 6.000 horas en 24 horas.</a> Ya lo sabes, cuenta con Ibancar y empieza a soñar a lo grande.</p>
        <!-- Boton Préstamo -->
        <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="flex-column pt-5">
                <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
            </div>
        </div>
      </div>
      <Section2 />
      <CreditoCocheS4 /> <!-- acordeón de preguntas -->
      <div class="relleno"></div>
      
      <div class="cards-prestamo">
        <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
          <div class="padding">
              <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
          </div>
        </div>
  
        <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
            <div class="card m-4"  >
              <a href="../prestamo-por-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamo por tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../dinero-rapido-con-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Dinero rápido con tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
                </div>
              </a>
            </div>
        </div>
        
        
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-madrid" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en Madrid</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Crédito por tu coche en Madrid. Recibe dinero por tu coche en Madrid</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-sevilla" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en &nbsp; Sevilla</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Credito por tu coche en Sevilla. Recibe dinero por tu coche en Sevilla</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-valencia" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en Valencia</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Crédito por tu coche en Valencia. Recibe dinero por tu coche en Valencia.</p>
                </div>
              </a>
            </div>
        </div>
  
        
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4"  >
              <a href="../credito-aval-coche-las-palmas" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito aval coche Las &nbsp; Palmas</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero usando tu coche como aval sin cambiar de titularidad.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
                </div>
              </a>
            </div>
        </div> 
  
  <!-- fila landing 4  -->
        <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
              <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-por-tu-coche-alicante" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos por tu coche Alicante</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">¿Necesitas un préstamo por tu coche en Alicante? Pues, tenemos la solución, entra aquí.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-aval-coche-valencia" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos aval coche &nbsp; Valencia</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
                </div>
              </a>
            </div>
      
        </div>
  
  <!-- fila landing 5  -->
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4"  >
              <a href="../credito-aval-coche-con-asnef" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito aval coche con Asnef</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../credito-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito con aval</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
                </div>
              </a>
            </div>
        </div>
  
        <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="flex-column p-5">
                <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
            </div>
        </div>
  
      </div>
      <div class="relleno"></div>
      <!-- No te pases de aquí gaston, gracias 2.0 :) -->
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Section1 from '@/components/Home/Section1.vue'
  import { useMeta } from 'vue-meta'
  import store from "@/store";
  import Section2 from '@/components/Home/Section2.vue'
  import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'
  
  // @ is an alias to /src
  export default {
    name: 'PrestamoDentista',
    components: {
      Section1,
      Section2,
      CreditoCocheS4
    },
    setup() {
  
      useMeta({
        title: 'Préstamo para autónomos con Ibancar',
        meta:[
          {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
          {name:'description', content:'Préstamo para entrada de piso con Ibancar'},
          {name:'keywords', content:'préstamo para autónomos'}
        ]
      })
      }
  }
  </script>
  
  <style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .naranja {
    color: $orange;
    font-weight: bold;
  }
  .texto-landing {
    padding: 3em 15em;
    line-height: 2em;
  }
  .card {
    width: 22rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 10rem;
  }
  .inline {
    display: inline;
  }
  .caret-sign, .caret-sign-city {
    margin-left: 25px;
    vertical-align: top;
    float: right;
  }
  .caret-sign-city {
    position: relative;
    top: -27px;
  }
  a.no {
    text-decoration: none;
    color: #333;
  }
  a.no:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    bottom: 1px;
    height: 10rem;
  }
  .relleno {
    height: 50px;
  }
  
  @import 'bootstrap/scss/_functions.scss';
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss';
  
  @include media-breakpoint-between(xs,sm) {
    .texto-landing {
      padding: 1.5em 2em;
    }
    .card {
      height: 100%;
    }
    a.no:hover {
      height: 100%;
    }
    .title-h1 {
      font-size: 32px;
    }
    .naranja {
      font-size: 24px;
    }
  }
  
  @include media-breakpoint-between(md,lg) {
    .texto-landing {
      padding: 1.5em 5em;
    }
  }
  
  @include media-breakpoint-between(xl,xxl) {
  
  }
  </style>